import { useEffect, useState } from "react";
import DataTable from "../../Components/DataTable";
import { baseurl } from "../../constants";

export default function Sliders() {
    const [data, setData] = useState()
    
    const getData = async () => {
        try {
          const response = await fetch(baseurl + "all-sliders", {
            method: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
    
          });
          const json = await response.json();
          console.log(json);
          setData(json)
          return json;
        } catch (error) {
          console.error(error);
        }
      };

      useEffect(() => {
        if (!data) {
          getData()
        }
      }, []);

      const table = {
        headers: ["صورة", "تاريخ الإضافة"],
        data: data&&data.map(row => [baseurl+"uploads/"+row.image,row.createdAt.toString().split("T")[0]]),
        title: "الإعلانات"
    }
    return data&&<DataTable table={table} />
}