import DataTable from "../../Components/DataTable";

export default function Countries(){

    const table ={
        headers : ["الاسم","كود الدولة","العملة","علم الدولة"],
        data: [["السعودية","966","SAR","https://cdn-icons-png.flaticon.com/512/6211/6211614.png"],
  
    ],
        title:"الشركات المصنعة"
    }
    return <DataTable table={table}/>
}