
import { useEffect, useState } from "react";

import { baseurl, uploadImage } from "../constants";
import useLoader from "../Components/loader/useLoader";

import BarChart from "../Components/chartjs/Bar";
import Donut from "../Components/chartjs/Donut";
import LineChart from "../Components/chartjs/LineChart";

export default function Home() {
  const [data, setData] = useState()


  const getData = async () => {
    try {
      const response = await fetch(baseurl + "admin-home", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });
      const json = await response.json();
      console.log(json);
      setData(json)
      return json;
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    getData()
  }, []);


  return data && <>
    {<>
      <div class="row gx-5 main-part row-cols-1 text-white row-cols-lg-2 row-cols-xl-4">

        <div className="col box cpanel">
          <div className="icon-part">
            <i className="fa fa-users" aria-hidden="true" />
            <br />
            <small>العملاء</small>
            <p>{data.users}</p>
          </div>
          <div className="card-content-part">
            <a href="#">عرض الكل </a>
          </div>
        </div>

        <div className="col box cpanel cpanel-green">
          <div className="icon-part">
            <i class="fas fa-user-check"></i>
            <br />
            <small>المسوقين</small>
            <p>{data.publishers}</p>
          </div>
          <div className="card-content-part">
            <a href="#">عرض الكل </a>
          </div>
        </div>

        <div className="col box cpanel cpanel-orange">
          <div className="icon-part">
            <i class="fas fa-user-tag"></i>
            <br />
            <small>الشركات</small>
            <p>{data.companies}</p>
          </div>
          <div className="card-content-part">
            <a href="#">عرض الكل </a>
          </div>
        </div>

        <div className="col box cpanel cpanel-skyblue">
          <div className="icon-part">
            <i class="bx bxs-buildings"></i>
            <br />
            <small>العقارات</small>
            <p>{data.ads}</p>
          </div>
          <div className="card-content-part">
            <a href="#">عرض الكل </a>
          </div>
        </div>
        <div className="charts card my-3">

          <div className="card-body">
            <h3 className="text-dark text-[25px] my-3 text-start">العقارات </h3>

            <div className="row ">
              <div className="col-md-5 chart box ">
                <Donut />
              </div>
           

            </div>
          </div>

        </div>







        {/* 
      <div class="col">
        <div class="card rounded-4  home-card">
          <div class="card-body ">
            <div class="d-flex align-items-center">
              <div class="">
                <p class="mb-1">السائقين المعتمدين</p>
                <h4 class="mb-0">33</h4>
                <p class="mb-0 mt-2 font-13"><i class="bi bi-arrow-up"></i><span>عدد السائقين : 11</span></p>
              </div>
              <div class="ms-auto widget-icon bg-white icon">
              <i className='bx bxs-user-check'></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card rounded-4  home-card">
          <div class="card-body ">
            <div class="d-flex align-items-center">
              <div class="">
                <p class="mb-1">السائقين غير المعتمدين</p>
                <h4 class="mb-0">33</h4>
                <p class="mb-0 mt-2 font-13"><i class="bi bi-arrow-up"></i><span>عدد السائقين : 20</span></p>
              </div>
              <div class="ms-auto widget-icon bg-white icon">
              <i className='bx bxs-user-x'></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="card rounded-4  home-card">
          <div class="card-body ">
            <div class="d-flex align-items-center">
              <div class="">
                <p class="mb-1">المستخدمين  المسجلين</p>
                <h4 class="mb-0">33</h4>
                <p class="mb-0 mt-2 font-13"><i class="bi bi-arrow-up"></i><span>عدد المستخدمين : 35</span></p>
              </div>
              <div class="ms-auto widget-icon bg-white icon">
              <i className="bi bi-people-fill" />
              </div>
            </div>
          </div>
        </div>
      </div> */}


      </div>


      {/* <div className="card">

     <div className="card-body">

     <div className="row p-3 m-1 ">
     <div class="col-xl-6 col-lg-6   mb-4">
  <div className="chart-box">
    <h3 className="chart-header">  المتاجر</h3>
    <ChartView data={data} />
  </div>
</div>

<div class="col-xl-6 col-lg-6   mb-4">

  <div className="chart-box">
    <h3 className="chart-header">  المتاجر حسب المدينة </h3>
    <PieChartView data={data} />
  </div>
</div>

<div class="col-xl-6 col-lg-6   mb-4">
  <div className="chart-box ">
    <h3 className="chart-header"> المستخدمين</h3>
    <LineChart data={data}/>
  </div>

</div>
<div class="col-xl-6 col-lg-6   mb-4">
  <div className="chart-box">
    <h3 className="chart-header"> العروض الفعالة</h3>
    <OrderStatusChart data={data} />
  </div>
</div>
</div>
     </div>
    </div> */}
    </>}
  </>


}