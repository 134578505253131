import DataTable from "../../Components/DataTable";

export default function Admins() {

    const table = {
        headers: ["الاسم", "البريد الإلكتروني", "رقم الجوال", "الصلاحية"],
        data: [["عبدالله العجمي", "abdullah.234@gmail.com", "053479456", "admin"],["عبدالله العجمي", "abdullah.234@gmail.com", "053479456", "admin"],

        ],
        title: "المديرين"
    }
    return <DataTable table={table} />
}