import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { baseurl } from "../constants";


var userName;
var password;
export default function SignIn(){
    const navigateTo = useNavigate()
    var [errors, setErrors] = useState();
  
    const login = async () => {
      const form = new FormData()
  
      console.log(userName + "  " + password);
  
      if (!userName) {
        setErrors(
          "اكتب اسم المستخدم",
        );
        return;
      }
      if (!password) {
        setErrors(
          "اكتب كلمة المرور",
        );
        return;
      }
      form.append("userName", userName)
      form.append("password", password)
      try {
        const response = await fetch(baseurl + "admin-login", {
  
          method: "POST",
          referrerPolicy: "unsafe-url",
          body: form
        });
        const json = await response.json();
  
        console.log(json);
        if (json.token) {
          localStorage.setItem("token", json.token)
          localStorage.setItem("id", json.user.id)
          navigateTo("/admin")
        } else {
          setErrors(
            "اسم المستخدم أو كلمة المرور"
          );
        }
        return json;
      } catch (error) {
  
        console.error(error);
  
      }
    };
  
  
    return <div className="row login">
     <div className="col-md-7 login-back">
      <img src="../images/login_back.png"/>
     </div>
     <div className="col-md-5">
     <div className="card-body text-center p-4 p-sm-5 w-[80%]">
            <h5 className="card-title">تسجيل الدخول</h5>
            <p className="card-text mb-5">لوحة التحكم والإدارة - نوط</p>
          
            <div className="row g-3 text-start">
              <div className="col-12 ">
                <label htmlFor="inputEmailAddress" className="form-label"> اسم المستخدم</label>
                <div className="ms-auto position-relative">
                  <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                    <i className="bi bi-envelope-fill" /></div>
                  <input autoComplete="off" onChange={(e) => (e.target.value != undefined ? userName = e.target.value : console.log(""))}
                    type="text" asp-for="UserName" className="form-control ps-5 h-[50px]" id="inputEmailAddress" placeholder=" اسم المستخدم" />
                  <span asp-validation-for="UserName" className="text-danger" />
                </div>
              </div>
              <div className="col-12">
                <label htmlFor="inputChoosePassword" className="form-label"> كلمة المرور</label>
                <div className="ms-auto position-relative">
                  <div className="position-absolute top-50 translate-middle-y search-icon px-3">
                    <i className="bi bi-lock-fill " /></div>
                  <input autoComplete="off" onChange={(e) => (e.target.value != undefined ? password = e.target.value : console.log(""))}
                    asp-for="Password" type="password" className="form-control  h-[50px] ps-5" id="inputChoosePassword" placeholder=" كلمة المرور" />
                  <span asp-validation-for="Password" className="text-danger" />
                </div>
              </div>

              <div className="col-6 text-end">
              </div>


              <div className="col-12">
                <div className="d-grid">

                  <button onClick={login}  className="btn btn-primary h-[50px] bg-[#28436C] border-[#28436C] radius-5">تسجيل
                    الدخول</button>
                </div>
              </div>

            </div>
          </div>
    </div>
    </div>
}