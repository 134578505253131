import { useEffect, useState } from "react";
import DataTable from "../../Components/DataTable";
import { baseurl } from "../../constants";

export default function Users() {
    const [data, setData] = useState()
    
    const getData = async () => {
        try {
          const response = await fetch(baseurl + "users", {
            method: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
    
          });
          const json = await response.json();
          console.log(json);
          setData(json)
          return json;
        } catch (error) {
          console.error(error);
        }
      };

      useEffect(() => {
        if (!data) {
          getData()
        }
      }, []);

      const table = {
        headers: ["الاسم", "البريد الإلكتروني", "رقم الجوال", "تاريخ الانضمام"],
        data: data&&data.map(row => [row.name,row.email,row.phone,row.createdAt.toString().split("T")[0]]),
        title: "العملاء"
    }
    return data&&<DataTable table={table} />
}