import DataTable from "../../Components/DataTable";

export default function CurrentTrips() {

    const table = {
        headers: ["رقم الرحلة", "التاريخ", "اسم المستخدم", "اسم السائق", "حالة الرحلة", "طريقة الدفع"],
        data: [["2364646", "2023-02-11 08:01:35", "محمد نورالدين", "حسين علي", "جارية", "كاش"],
        ["2364646", "2023-02-11 08:01:35", "محمد نورالدين", "حسين علي", "جارية", "كاش"],
         ["2364646", "2023-02-11 08:01:35", "محمد نورالدين", "حسين علي", "جارية", "كاش"], ["2364646", "2023-02-11 08:01:35", "محمد نورالدين", "حسين علي", "جارية", "كاش"], ["2364646", "2023-02-11 08:01:35", "محمد نورالدين", "حسين علي", "جارية", "كاش"],

        ],
        title: "الرحلات الجارية"
    }
    return <DataTable table={table} />
}