import { useEffect, useState } from "react";
import DataTable from "../../Components/DataTable";
import { baseurl } from "../../constants";

export default function Ads() {
    const [data, setData] = useState()
    
    const getData = async () => {
        try {
          const response = await fetch(baseurl + "all-ads", {
            method: "GET",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
    
          });
          const json = await response.json();
          console.log(json);
          setData(json)
          return json;
        } catch (error) {
          console.error(error);
        }
      };

      useEffect(() => {
        if (!data) {
          getData()
        }
      }, []);

      const table = {
        headers: ["صورة","العنوان","السعر - ريال", "المدينة", "النوع","القسم", "تاريخ الإضافة"],
        data: data&&data.map(row => [baseurl+"uploads/"+row.ad.images.split(",")[0]
        ,row.ad.name,row.ad.price,row.city.name,row.publisher.name,row.type=="sell"?"عقار للبيع":"عقار للإيجار",row.ad.createdAt.toString().split("T")[0]]),
        title: "العقارات"
    }
    return data&&<DataTable table={table} />
}