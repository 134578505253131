import DataTable from "../../Components/DataTable";

export default function Index(){

    const table ={
        headers : ["اسم الصلاحية"],
        data: [["Admin"],["Super-Admin"]],
        title:"الصلاحيات"
    }
    return <DataTable table={table}/>
}