import DataTable from "../../Components/DataTable";

export default function CarMake(){

    const table ={
        headers : ["الاسم","الحالة"],
        data: [["تويوتا","مفعل"],
        ["نيسان","مفعل"],
        ["كيا","مفعل"],
    
    ],
        title:"الشركات المصنعة"
    }
    return <DataTable table={table}/>
}