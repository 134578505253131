import DataTable from "../../Components/DataTable";

export default function ActiveDrivers() {

    const table = {
        headers: ["الاسم", "البريد الإلكتروني", "رقم الجوال", "الحالة"],
        data: [["عبدالله محمد", "abdullah.234@gmail.com", "053479456", "مفعل"],
        ["عبدالله محمد", "abdullah.234@gmail.com", "053479456", " مفعل"],

        ],
        title: "السائقين المعتمدين"
    }
    return <DataTable table={table} />
}