
import loadjs from 'loadjs';
import React, { useState } from "react";
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import ExternalScripts from './ExternalScripts';

export default function Sidebar() {

  const navigateTo = useNavigate()

  return <aside className="sidebar-wrapper" data-simplebar="true">
    <ExternalScripts />

    <div className="sidebar-header">
      <div>
        <img src="../images/logo.png" className="logo-icon" alt="logo icon" />
      </div>
      <div>
        <h4 className="logo-text">نوط </h4>
      </div>
      <div className="toggle-icon ms-auto"> <i className="bi bi-list" />
      </div>
    </div>
    {/*navigation*/}
    <ul className="metismenu" id="menu">
      <li>
        <a onClick={e => navigateTo("/admin/home")} aria-expanded="true">
          <div className="parent-icon"><i className='bx bxs-tachometer'></i>
          </div>
          <div className="menu-title">الرئيسية</div>
        </a>
      </li>

      {/* Users */}
      <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i className='bx bxs-user-rectangle'></i>
          </div>
          <div className="menu-title">العملاء</div>
        </a>
        <ul>
          <li onClick={e => navigateTo("/admin/users")}> <a ><i className="bi bi-circle" />قائمة العملاء</a>
          </li>
        </ul>
      </li>

      {/* Companies */}
      <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i className='bi bi-people-fill'></i>
          </div>
          <div className="menu-title">الشركات</div>
        </a>
        <ul>

          <li onClick={e => navigateTo("/admin/companies")}> <a ><i className="bi bi-circle" />قائمة الشركات</a>
          </li>
        </ul>
      </li>

      {/* Companies */}
      <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i className='bx bxs-user'></i>
          </div>
          <div className="menu-title">المسوقين</div>
        </a>
        <ul>

          <li onClick={e => navigateTo("/admin/publishers")}> <a ><i className="bi bi-circle" />قائمة المسوقين</a>
          </li>
        </ul>
      </li>

         {/* Ads */}
        <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i className='bx bxs-business'></i>
          </div>
          <div className="menu-title">العقارات</div>
        </a>
        <ul>

          <li onClick={e => navigateTo("/admin/ads")}> <a ><i className="bi bi-circle" />قائمة العقارات</a>
          </li>
        </ul>
      </li>

               {/* Categories */}
               <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i className='bx bxs-map-alt'></i>
          </div>
          <div className="menu-title">أقسام العقارات</div>
        </a>
        <ul>

          <li onClick={e => navigateTo("/admin/categories")}> <a ><i className="bi bi-circle" />قائمة الأقسام </a>
          </li>
        </ul>
      </li>


       {/* sliders */}
      <li>
        <a href="javascript:;" className="has-arrow">
          <div className="parent-icon"><i className='bx bxs-buildings'></i>
          </div>
          <div className="menu-title">الإعلانات</div>
        </a>
        <ul>

          <li onClick={e => navigateTo("/admin/sliders")}> <a ><i className="bi bi-circle" />الإعلانات</a>
          </li>
        </ul>
      </li>

      <li>
        <a onClick={e => {
          localStorage.clear()
          navigateTo("/")
        }} aria-expanded="true">
          <div className="parent-icon"><i class='bx bxs-exit'></i>
          </div>
          <div className="menu-title">تسجيل الخروج</div>
        </a>
      </li>

      {/*end navigation*/}
    </ul></aside>
}

