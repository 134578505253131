import DataTable from "../../Components/DataTable";
export default function CanceledTrips() {

    const table = {
        headers: ["رقم الرحلة", "التاريخ", "اسم المستخدم", "اسم السائق", "ألغيت بواسطة", "سبب الإلغاء"],
        data: [["2364646", "2023-02-11 08:01:35", "محمد نورالدين", "حسين علي", "المستخدم", "-"],
        ["2364646", "2023-02-11 08:01:35", "محمد نورالدين", "حسين علي", "المستخدم", "-"],
         ["2364646", "2023-02-11 08:01:35", "محمد نورالدين", "حسين علي", "المستخدم", "-"],
          ["2364646", "2023-02-11 08:01:35", "محمد نورالدين", "حسين علي", "المستخدم", "-"], ["2364646", "2023-02-11 08:01:35", "محمد نورالدين", "حسين علي", "المستخدم", "-"],
        ],
        title: "الرحلات الملغاة"
    }
    return <DataTable table={table} />
}