import DataTable from "../../Components/DataTable";

export default function Drivers() {

    const table = {
        headers: ["الاسم", "البريد الإلكتروني", "رقم الجوال", "الحالة"],
        data: [["عبدالله محمد", "abdullah.234@gmail.com", "053479456", "مفعل"],
        ["عبدالله محمد", "abdullah.234@gmail.com", "053479456", "غير مفعل"],

        ],
        title: "السائقين المسجلين"
    }
    return <DataTable table={table} />
}