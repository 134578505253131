import DataTable from "../../Components/DataTable";

export default function CarModel(){

    const table ={
        headers : ["الاسم","الشركة المصنعة","الحالة"],
        data: [["Acura","نيسان","مفعل"],
        ["Acura","نيسان","مفعل"],
        ["Acura","نيسان","مفعل"]
    
    ],
        title:"موديل السيارات"
    }
    return <DataTable table={table}/>
}