
import './App.css';
import Dashboard from './Components/Dashboard';
import Home from './Pages/Home';
import Login from './Pages/Login';
import { BrowserRouter as Router, Route, Routes, } from "react-router-dom";
import Index from './Pages/Roles/Index';
import CarTypes from './Pages/CarTypes/Index';
import CarMake from './Pages/CarMake/CarMake';
import CarModel from './Pages/CarModel/CarModel';
import Countries from './Pages/Country/Countries';
import CompletedTrips from './Pages/Trips/CompletedTrips';
import CurrentTrips from './Pages/Trips/CurrentTrips';
import CanceledTrips from './Pages/Trips/CanceledTrips';
import ScheduledTrips from './Pages/Trips/ScheduledTrips';
import Admins from './Pages/Admins/Admins';
import ActiveDrivers from './Pages/Drivers/ActiveDrivers';
import SusPendingDrivers from './Pages/Drivers/SusPendingDrivers';
import Drivers from './Pages/Drivers/Drivers';
import SignIn from './Pages/SignIn';
import Users from './Pages/Users/Users';
import Companies from './Pages/Companies/Companies';
import Publishers from './Pages/Publishers/Publishers';
import Ads from './Pages/Ads/Ads';
import Categories from './Pages/Categories/Categories';
import Sliders from './Pages/Sliders/Sliders';

function App() {
  return (
    <div className="App">
      <Router >
        <Routes >
          <Route path="/" element={<SignIn />} />
          <Route path="login" element={<SignIn />} />
          <Route path="admin" element={<Dashboard />}>
            <Route path="" element={<Home />} />
            <Route path="home" element={<Home />} />

            {/* Users */}
            <Route path="users" element={<Users />} />

            {/* Companies */}
            <Route path="companies" element={<Companies />} />

            {/* publishers */}
            <Route path="publishers" element={<Publishers />} />

            {/* ads */}
            <Route path="ads" element={<Ads />} />

              {/* categories */}
             <Route path="categories" element={<Categories />} />


               {/* sliders */}
                <Route path="sliders" element={<Sliders />} />











            {/* Roles */}
            <Route path="roles/index" element={<Index />} />

            {/* Car Types */}
            <Route path="car-types/index" element={<CarTypes />} />

            {/* Car Types */}
            <Route path="car-make/index" element={<CarMake />} />

            {/* Car Model */}
            <Route path="car-model/index" element={<CarModel />} />

            {/* Country */}
            <Route path="country/index" element={<Countries />} />

            {/* Trips */}
            <Route path="trips/completed" element={<CompletedTrips />} />
            <Route path="trips/current" element={<CurrentTrips />} />
            <Route path="trips/canceled" element={<CanceledTrips />} />
            <Route path="trips/schedualed" element={<ScheduledTrips />} />

            {/* Admins */}
            <Route path="admins" element={<Admins />} />

            {/* Admins */}
            <Route path="drivers/active" element={<ActiveDrivers />} />
            <Route path="drivers" element={<Drivers />} />
            <Route path="drivers/suspending" element={<SusPendingDrivers />} />


          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
