import DataTable from "../../Components/DataTable";

export default function CarTypes(){

    const table ={
        headers : ["الاسم","الصورة","الحالة"],
        data: [["سيارة صغيرة","https://cdn-icons-png.flaticon.com/128/2207/2207521.png","مفعل"],
        ["سيارة متوسطة","https://cdn-icons-png.flaticon.com/128/2207/2207521.png","مفعل"],
        ["سيارة كبيرة","https://cdn-icons-png.flaticon.com/128/2207/2207521.png","مفعل"],
    
    ],
        title:"أنواع المركبات"
    }
    return <DataTable table={table}/>
}