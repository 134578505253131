import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  
  ArcElement,
  Title,
  Tooltip,
  LineElement,
  Legend,
  PointElement,
} from 'chart.js';
import { Line } from 'react-chartjs-2/dist';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  fill:true,
  backgroundColor:'#16A085',
  borderColor:'#16A085',
  borderWidth:5,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'Chart.js Bar Chart',
    },
  },
};


 const data={
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May'],
    datasets: [
      {
        label: '2018 Sales',
        data: [300, 700, 450, 750, 450]
      }
    ]
  };

export default function LineChart() {
  return <Line data={data} options={options}/>;
}
