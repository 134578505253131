import { Link } from "react-router-dom";
import { baseurl } from "../constants";

export default function DataTable({ table }) {
    return <div>
        {/* {loader} */}
        <div className="page-breadcrumb d-none d-sm-flex align-items-center mb-3">
            <div className="breadcrumb-title pe-3">{table.title}</div>

            {/* <div className="ms-auto">
                <Link to={"/admin/field/add"}>
                    <div className="btn-group">
                        <a className="btn btn-success">إضافة +</a>
                    </div>
                </Link>
            </div> */}
        </div>

        <hr />

        <div className="card">
            <div className="card-body">
                <div className="table-responsive">
                    <div id="example2_wrapper" className="dataTables_wrapper dt-bootstrap5">
                        <div className="row">
                            <div className="col-sm-12">

                                <p>
                                </p><div className="row justify-content-start">
                                    <div className="col-12 col-md-4">
                                        <div className="card-body row no-gutters align-items-start">
                                            {/*end of col*/}
                                            <div className="col">
                                                <input autoComplete="off" name="SearchData" className="form-control form-control form-control-borderless" type="search" placeholder="بحث في القائمة" />
                                            </div>
                                            {/*end of col*/}
                                            <div className="col-auto">
                                                <button className="btn btn btn-primary" >بحث</button>
                                            </div>
                                            {/*end of col*/}
                                        </div>
                                    </div>
                                    {/*end of col*/}
                                </div>
                                <p />

                                <table id="DataTable" className="table table-striped table-bordered dataTable" role="grid" aria-describedby="example2_info">
                                    <thead>
                                        <tr role="row">
                                            <th className="sorting_asc" tabIndex={0} aria-controls="example2" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Name: activate to sort column descending">م </th>
                                            {
                                                table.headers.map((item) => <th className="sorting_asc" tabIndex={0} aria-controls="example2" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Name: activate to sort column descending">{item} </th>)
                                            }
                                            <th className="sorting_asc" tabIndex={0} aria-controls="example2" rowSpan={1} colSpan={1} aria-sort="ascending" aria-label="Name: activate to sort column descending">إجراء </th>

                                        </tr>
                                    </thead>
                                    <tbody>

                                        {table.data.map((item, index) => <tr role="row" className="odd">
                                            <td><span className="mx-3">{index + 1}</span></td>
                                            {

                                                item.map((t) =>  (t.toString().includes("https")) ? <td><img src={t} className="rounded" width={44} height={44} alt="" /></td> : <td><span className="mx-3">{t}</span></td> )
                                            }

                                            <td>
                                                {/* <Link state={{
                                                    row: item,
                                                }} to={"/admin/fields/detail"}>
                                                    <a asp-action="Details" className="btn btn-primary m-1 text-center"><i className="far fa-eye" /></a>
                                                </Link>

                                                <Link state={{
                                                    row: item,
                                                }} to={"/admin/field/edit"}>

                                                    <a asp-action="Edit" asp-route-id="@item.Id" className="btn btn-success m-1 text-center"><i className="fas fa-edit" /></a>


                                                </Link>

                                                <Link state={{
                                                    row: item,
                                                }} to={"/admin/field/delete"}>

                                                    <a asp-action="Delete" asp-route-id="@item.Id" className="btn btn-danger m-1 text-center"><i className="far fa-trash-alt" /></a>


                                                </Link> */}

                                            </td>

                                        </tr>)}

                                    </tbody>
                                </table>
                                <br />

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
}